@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap'); */

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-family: 'Nunito', sans-serif; */
  height: 100%;
}

.login_signup .bgi-size-cover {
  background-image: url("./assets/media/bg/bg-2.jpg");
  min-height: 100vh;
}

.login_signup .login.login-5 .login-form {
  max-width: 550px;
}

.login_signup input::-webkit-outer-spin-button,
.login_signup input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login_signup input[type="number"] {
  -moz-appearance: textfield;
}

.card.card-custom.card-stretch {
  height: auto;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 0;
}

.form-group {
  margin-bottom: 1.5rem;
}


/*========================================================*/
/*======================== New Custom ========================*/
/*========================================================*/

.border-logo{
  border-color: #54b9a8b5 !important;
}

.btn.btn-primary {
  color: #fff !important;
  background-color: #54b9a8 !important;
  border-color: #54b9a8 !important;
}
.btn.btn-primary.tab {
  color: #54b9a8 !important;
  background-color: #54b9a820 !important;
  border-color: transparent !important;
}
.btn.btn-primary.tab.active {
  color: #fff !important;
  background-color: #54b9a8 !important;
  border-color: transparent !important;
}

.btn.btn-light-primary {
  color: #54b9a8 !important;
  background-color: #54b9a820 !important;
  border-color: transparent;
}
.btn.btn-light-primary.tab:hover {
  color: #54b9a8 !important;
  background-color: #54b9a820 !important;
  border-color: transparent !important;
}
.btn.btn-light-primary.tab.active {
  color: #fff !important;
  background-color: #54b9a8 !important;
  border-color: transparent !important;
}
.btn.btn-light-primary.tab.active:hover {
  color: #fff !important;
  background-color: #54b9a8 !important;
  border-color: transparent !important;
}

.pointer {
  cursor: pointer;
}

.speciality select{
  margin-right: 10px !important;
  padding-right: 10px !important;
}

/*========================================================*/
/*======================== Home ========================*/
/*========================================================*/
.homepage .buttons {
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
}

.homepage .buttons a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
}

.homepage .buttons a i {
  font-size: 20px;
  margin-bottom: 5px;
}

.homepage .buttons a span {
  font-size: 15px;
}

.homepage .lists i.la {
  font-size: 3rem;
  color: #3699ff;
  background-color: #e1f0ff;
  border-radius: 50%;
  padding: 10px;
}

.homepage .card.card-custom.card-stretch.gutter-b {
  transition: all 0.5s !important;
}

.homepage .card.card-custom.card-stretch.gutter-b:hover {
  transform: translateY(-5px);
}

.homepage select,
.edit_patient .meal_select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}
.profile .meal_select {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}
.login_signup .meal_select {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}

.homepage select.meal_select {
  background-position-x: 97%;
  background-position-y: 4px;
}

.homepage select.number_of_dose {
  background-position-x: 88%;
  background-position-y: 3px;
}

.edit_patient .meal_select {
  background-position-x: 97%;
}

.homepage input::-webkit-outer-spin-button,
.homepage input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.homepage input[type="number"] {
  -moz-appearance: textfield;
}

.homepage .add_medication {
  border: 1px solid #e4e6ef;
  /* height: calc(1.5em + 1.65rem + 2px); */
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*========================================================*/
/*======================== My Rx =========================*/
/*========================================================*/
.myrx .lists .card {
  border: 1px solid #54b9a8;
}

.myrx .lists .card i {
  color: #54b9a8;
  background: #54b9a820;
  padding: 6px;
  font-size: 22px;
  border-radius: 5px;
}

.myrx .lists .card .symbol.symbol-60.symbol-2by3 .symbol-label {
  height: 90px;
}

/*========================================================*/
/*======================== Search ========================*/
/*========================================================*/
.myrx.search .lists .medicine img {
  height: 65px;
}

.medicine_bg {
  background: #e1f0ff;
}

/*========================================================*/
/*======================== Create ========================*/
/*========================================================*/
.homepage.create .card.medicine_bg {
  background: transparent;
  border: 1px solid #e4e6ef;
}

.homepage.create .card.medicine_bg:hover {
  transform: initial !important;
}

.homepage.create .card.medicine_bg .trash {
  color: #3699ff;
  background: #eee;
  padding: 6px;
  font-size: 22px;
  border-radius: 5px;
  padding-right: 8px;
}

.homepage.create .card.medicine_bg .up_down i {
  background: #eee;
  color: #3699ff;
  padding: 1px 7px;
  cursor: pointer;
}

.homepage.create .card.medicine_bg .up_down i:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 1px solid #add5ff;
}

.homepage.create .card.medicine_bg .up_down i:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/*========================================================*/
/*==================== Patient Profile ===================*/
/*========================================================*/
.myrx.search.patient_profile i.edit {
  color: #54b9a8;
  background: #54b9a820;
  padding: 6px;
  font-size: 22px;
  border-radius: 5px;
}

/* Modal */
.medicine_modal select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 5px;
}

.text-primary {
  color: #54b9a8 !important;
}

/*========================================================================*/
/*========================= Prescription Preview =========================*/
/*========================================================================*/
.inner_card_header {
  background-color: #54b9a8;
  margin-bottom: 0;
  color: #fff;
  font-weight: 600;
  padding: 12px 0;
}

.remove_bg {
  background-color: #54b9a8 !important;
  border-color: #54b9a8 !important;
  color: #fff;
}

.remove_bg_light {
  background-color: #54b9a820 !important;
  border-color: #54b9a800 !important;
  color: #54b9a8;
}
.remove_bg_light i {
  background-color: #54b9a800 !important;
  border-color: #54b9a800 !important;
  color: #54b9a8;
}
.remove_bg_light:hover {
  background-color: #54b9a8 !important;
  border-color: #54b9a8 !important;
  color: #fff;
}
.remove_bg_light:hover i{
  background-color: #54b9a8 !important;
  border-color: #54b9a8 !important;
  color: #fff;
}

/*========================= start =========================*/

.prescription_preview .pres_header {
  background: #1b927d;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.prescription_preview .pres_header .rx {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prescription_preview .pres_header .rx h1 {
  font-size: 40px;
  font-weight: 600;
}

.prescription_preview .pres_header .rx p {
  font-weight: 500;
}

.prescription_preview .pres_header .details h4 {
  font-weight: 600;
}

.prescription_preview .pres_body .patient_info {
  border: 2px solid #54b9a8;
  border-radius: 5px;
}

.prescription_preview .pres_body .patient_info .item {
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;
  border-bottom: 1px solid #ddd;
  color: #777;
}

.prescription_preview .pres_body .patient_info .item:last-child {
  border-bottom: 0px solid #ddd;
}

.prescription_preview .pres_body .patient_info .item span {
  font-weight: 600;
}

.prescription_preview .pres_body .pres_items {
  border: 2px solid #54b9a8;
  border-radius: 5px;
  margin-top: 15px;
  padding: 13px 20px;
  display: flex;
}

.prescription_preview .pres_body .pres_items .icon {
  margin-right: 25px;
}

.prescription_preview .pres_body .pres_items .icon img {
  background: #54b9a830;
  border-radius: 50%;
  width: 55px;
}

.prescription_preview .pres_body .pres_items .details button {
  min-width: 125px;
  max-width: 130px;
  font-size: 13px;
  font-weight: 500 !important;
  /* display: flex; */
  padding: 5px 8px;
}

.prescription_preview .pres_body .pres_items .details p {
  font-weight: 400;
  margin-top: 10px;
}

.prescription_preview .pres_body .medications {
  border: 2px solid #54b9a8;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.prescription_preview .pres_body .medications .item {
  padding: 13px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.prescription_preview .pres_body .medications .item:last-child {
  border-bottom: 0 solid #ddd;
}

.tablet_img_bg {
  background: #54b9a830;
  border-radius: 50%;
}

.prescription_preview .pres_body .medications .item img {
  background: #54b9a830;
  border-radius: 50%;
  width: 55px;
  margin-right: 25px;
}

.prescription_preview .pres_body .medications .item .details {
  font-weight: 400;
}

.prescription_preview .pres_body .medications .item .details span {
  font-weight: 600;
}

.prescription_preview .pres_body .followup {
  border-radius: 5px;
  margin-top: 25px;
  display: flex;
  font-weight: 600;
  color: #fff;
  background-color: #54b9a8;
}

.prescription_preview .pres_body .followup p:first-child {
  background: #1b927d;
  padding: 17px 20px;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  min-width: 160px;
}

.prescription_preview .pres_body .followup p:last-child {
  padding: 17px 25px;
  font-weight: 400;
}

.prescription_preview .pres_body .sponsor {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  max-width: 335px;
  margin: 0 auto;
  background: #b9c7d880;
  margin-top: 25px;
  border-radius: 5px;
  padding: 18px 30px;
}

.prescription_preview .pres_body .sponsor p {
  font-weight: 600;
}

.prescription_preview .pres_body .buttons {
  margin-top: 25px;
}

.prescription_preview .pres_body .buttons button {
  padding: 18px 0;
  font-size: 17px;
  font-weight: 700;
}

/* Date Picker */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0rem;
  background-color: #3699ff !important;
  color: #fff;
}

.react-datepicker__day--selected:hover {
  border-radius: 0rem;
  background-color: #1086ff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 3px;
  background-color: #54b9a8 !important;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
  border-radius: 0rem;
  background-color: #1086ff;
}

.react-datepicker__header {
  background-color: #54b9a820;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 12px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.3rem;
}

.react-datepicker__input-container input[type="text"] {
  background: url("./assets/img/calender.svg") no-repeat 100%;
  background-size: 21px;
  background-position-x: 98%;
}

.react-datepicker__input-container .patient_dob {
  background-position-x: 96% !important;
}

.react-datepicker__input-container .patient_dob:disabled {
  background-color: #f3f6f9;
}

.css-yk16xz-control {
  min-height: 49px !important;
  border: 1px solid #e4e6ef !important;
}

.css-1pahdxg-control {
  min-height: 49px !important;
}

.css-g1d714-ValueContainer {
  padding: 0 19px !important;
}

.css-yk16xz-control:hover {
  border: 1px solid #e4e6ef !important;
}

/*========================= Responsive =========================*/
@media screen and (max-width: 767px) {
  .prescription_preview .main_column {
    padding: 0;
  }

  .share_print {
    padding: 0;
  }

  .card-body {
    padding: 1.45rem;
  }

  .prescription_preview .pres_body .medications {
    margin-top: 25px;
  }

  .prescription_preview .pres_body .patient_info .item,
  .prescription_preview .pres_body .pres_items,
  .prescription_preview .pres_body .medications .item {
    padding: 13px 17px;
  }

  .prescription_preview .pres_body .followup p:first-child {
    padding: 17px 20px;
    min-width: 122px;
  }
  .prescription_preview .pres_body .followup p:last-child {
    padding: 17px 20px;
  }

  .prescription_preview .pres_body .pres_items .icon,
  .prescription_preview .pres_body .medications .item img {
    margin-right: 23px;
  }

  .prescription_preview .pres_body .buttons button {
    padding: 12px 0;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .prescription_preview .pres_body .buttons {
    display: none !important;
  }
}

.vendorListHeading th {
  background-color: #1a4567 !important;
  color: white !important;
}

/*========================================================*/
/*==================== Loading Skleton ===================*/
/*========================================================*/
.homapage-loading .icons .react-loading-skeleton.css-1eg0sfo-skeletonStyles-Skeleton {
  border-radius: 50%;
}
