/*************************/
/**********SCB************/
.sticky-top::-webkit-scrollbar {
  width: auto;
  height: 15px;
}

.sticky-top::-webkit-scrollbar-track {
  background-color: #7c839910;
  border-radius: 0px;
}

.sticky-top::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 3.5px solid transparent;
  background-clip: content-box;
  /* background-color: #54b9a8; */
  min-width: 200px;
  background-image: linear-gradient(to right, #469e8f30, #54b9a8, #469e8f30);
}
.sticky-top::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to right, #54b9a8, #469e8f30, #54b9a8);
}
/*************************/
.preview-prescription-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* min-height: 90vh; */
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #7c839990;

  min-width: 900px;
  max-width: 800px;
  font-family: 'Nunito', sans-serif;
}

.preview-header-wrapper {
  display: flex;
  min-height: 160px;
  background-color: #f8f8f8;
  padding: 20px 40px 20px 40px;
}

.preview-header-partL {
  flex-grow: 1;
}
.preview-logo-container {
  width: 100px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  margin: 0 5px;
}
.preview-logo-wrapper {
  object-fit: contain;
}
.preview-logo-wrapper img {
  width: 100%;
}
.preview-logo-title {
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  bottom: -5px;
}

.preview-header-partR {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.preview-header-textR {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: end;
  font-weight: 600;
  font-size: 11px;
  color: #7c8399;
}

.preview-doc-name {
  font-size: 25px;
  font-weight: 700;
  color: #15978ae0;
}

.preview-doc-speciality {
  font-size: 13px;
  font-weight: 700;
  margin-top: -5px;
}
.preview-doc-professional-bio {
  font-size: 12px;
  font-weight: 600;
}

.preview-doc-contact-section {
  display: flex;
  justify-content: flex-end;
}

.preview-doc-mobile {
  display: block;
  font-size: 10.5px;
  font-style: italic;
}
.preview-doc-email {
  margin-left: 5px;
  font-style: italic;
}
.preview-doc-address {
  font-style: italic;
}

.preview-patient-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2px;
  /* margin-top: 15px; */
  padding: 2px 37px 2px 37px;
  border-width: 2px;
  border-left-width: 0;
  border-right-width: 0;
  border-color: #7c8399;
  border-style: solid;
}

.preview-patient-details {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.preview-patient-part {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: row;
  padding: 5px;
  font-size: 11px;
  color: #7c8399e0;
  font-weight: 600;
}

.preview-patient-part span {
  color: #7c8399;
  font-weight: 700;
  margin-left: 10px;
}

/* .preview-patient-details .preview-patient-part:first-child{
    flex-grow: 2;
} */

.preview-prescription-details-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  /* margin-bottom: 20px; */
  padding: 0px 40px 0px 40px;
}

.preview-details-leftside {
  /* flex-grow: 2; */
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  min-width: 250px;
  max-width: 250px;
}
.preview-details-divider {
  width: 2px;
  background-color: #7c8399;
  margin: 0 20px 0 10px;
}
.preview-details-rightside {
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.preview-prescription-item {
  flex-grow: 1;
  margin: 2px 0;
}
.preview-prescription-item-title {
  padding: 2px 2px;
  font-size: 13px;
  font-weight: 700;
}
.preview-prescription-item-content {
  padding-left: 30px;
  font-weight: 600;
  font-size: 11px;
}

/* .preview-details-leftside .preview-prescription-item {
    max-height: 140px;
} */
.preview-details-leftside .preview-prescription-item:last-child {
  flex-grow: 1.5;
  max-height: max-content;
}

.preview-investigation-wrapper {
  margin: 0 10px 0 0;
  min-width: 40%;
}

.preview-details-rightside .preview-prescription-item:first-child {
  flex-grow: 1;
  margin-bottom: 15px;
}

.preview-details-rightside .preview-prescription-item {
  margin-bottom: 35px;
  min-height: 90px;
}

.preview-details-rightside
  .preview-prescription-item
  .preview-prescription-item-content {
  margin-left: 30px;
}

/* Medication */

.preview-medication-wrapper {
  margin: 0 20px 10px 0;
  padding: 5px 0;
  font-size: 11px;
  border-width: 0;
  /* border-bottom-width: 0.5px; */
  border-style: solid;
  border-color: #7c839920;
}
.preview-medication-brief {
  font-weight: 700;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  text-align: center;
}
.preview-medication-brief .preview-medicine-type {
  margin-left: 5px;
  font-size: 10.5px;
  font-weight: 600;
  color: #7c8399e0;
}
.preview-medication-brief .preview-medicine-strength {
  margin-left: 5px;
  font-size: 10.5px;
  font-weight: 600;
  color: #7c8399e0;
}
.preview-medication-schedule {
  font-weight: 600;
  font-size: 11px;
}
.preview-medication-does-meal-days {
  display: flex;
  flex-direction: row;
  font-weight: 400;
}
.preview-medication-doseDrop,
.preview-medication-mealtime {
  margin-right: 5px;
}
.preview-medication-advice {
  font-weight: 800;
}
.preview-medication-advice span {
  font-weight: 400;
}

#preview-poweredby-sec {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-bottom: 0;

  justify-content: center;
  flex-wrap: wrap;
  padding-right: 10px;
  align-items: flex-end;
  margin-top: -5px;
  max-height: 95px;
}

.preview-signature-container {
  display: flex;
  justify-content: flex-end;
  /* margin-top: -10px; */
  margin-bottom: 55px;
}
.preview-signature-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-right: 50px; */
}
.preview-signature-image-wrapper {
  height: 45px;
  width: 135px;
  margin: 5px 0 0 0;
  padding: 5px 10px;
  border-width: 0mm;
  border-style: solid;
  border-color: #7c839950;
  border-radius: 10px;
  overflow: hidden;
}
.preview-signature-image {
  width: 100%;
  height: 100%;
}
.preview-signature-dash {
  margin-top: -6px;
}
.preview-signature-footer {
  margin-top: -8px;
  font-size: 12px;
  font-weight: 600;
}

.preview-button-container {
  /* margin-top: 20px; */
  display: flex;
  background-color: #fff;
  border-width: 0px;
  border-style: solid;
  border-color: #7c8399;
  /* flex-wrap: wrap; */
  min-width: 1600px;
  max-width: 1600px;
}

.preview-share-container,
.preview-download-container,
.preview-copy-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  /* padding: 10px 0; */
  cursor: pointer;
  background-color: #54b9a8;
}
.preview-share-container,
.preview-copy-container {
  border-width: 0 2px 0 0;
  border-style: solid;
  /* border-color: #7c8399; */

  border-color: #fff;
}

.preview-copy-container {
  /* flex-grow: 2;
    padding-right: 4px; */

  min-width: 440px;
  max-width: 440px;
}

.preview-share-container,
.preview-download-container {
  flex-grow: 1;
}

.preview-download-container {
  padding-right: 10px;
}

.preview-share-wrapper,
.preview-download-wrapper,
.preview-copy-wrapper {
  /* background-color: #54b9a8; */
  color: #fff;
  padding: 10px 20px;
  min-width: 150px;
  text-align: center;
  border-radius: 5px;
  font-size: 23px;
  font-weight: 600;
}

@media (max-width: 880px) {
  .preview-prescription-page {
    padding: 10px 15px;
    display: block;
  }
}

/* @media (max-width: 880px) {
    .preview-prescription-page {
        padding: 10px 15px;
    }
    #poweredby-sec{
        padding-right: 0;
    }
    
    
}

@media only screen and (max-width: 900px) {
    .preview-header-wrapper{
        min-height: 100px;
    }
    .preview-header-partL {
        flex-grow: 1;
    }
    .preview-logo-container{
        width: 100px;
    }
    .preview-logo-title{
        font-size: 15px;
        bottom: 0;
    }
    
    .preview-patient-part {
        width: 49%;
    }
    .preview-doc-name {
        font-size: 18px;
    }
    .preview-header-textR,
    .preview-doc-mobile {
        font-size: 12px;
    }
    .preview-doc-professional-bio,
    .preview-doc-speciality {
        font-size: 13px;
    }
    .preview-patient-part {
        font-size: 13px;
    }
    .preview-prescription-details-container {
        flex-direction: column;
    }
    .preview-details-divider {
        width: 100%;
        height: 1px;
        background-color: #7c839950;
    }
    .preview-prescription-item {
        margin: 15px 0;
    }
    .preview-prescription-item-title {
        padding: 0 10px;
        font-size: 16px;
    }
    .preview-prescription-item-content {
        padding-left: 40px;
        font-size: 12px;
    }
    
    .preview-details-rightside .preview-prescription-item:first-child{
        margin-top: 25px;
    }
    .preview-details-rightside .preview-prescription-item .preview-prescription-item-content{
        margin-left: 20px;
    }
    .preview-medication-wrapper {
        font-size: 11.5px;
    }
    .preview-medication-wrapper .preview-medication-brief {
        font-size: 13.5px;
    }

    .preview-signature-wrapper {
        margin: 20px 0 5px 0;
    }
    .preview-signature-image-wrapper {
        height: 50px;
        width: 150px;
    }
    
    .preview-copy-container{
        flex-grow: 1;
        min-width: auto;
        max-width: none;
    }
    .preview-copy-container, .preview-share-container, .preview-download-container {
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: #fff;
    }
}

@media (max-width: 500px) {
    .preview-logo-container{
        width: 85px;
    }
    .preview-doc-mobile{
        display: none;
    }
    .preview-details-rightside .preview-prescription-item .preview-prescription-item-content{
        margin-left: 10px;
    }
    .preview-signature-footer{
        font-size: 15px;
    }
}

@media (max-width: 380px) {
    .preview-details-rightside .preview-prescription-item .preview-prescription-item-content{
        margin-left: 0;
    }
    .preview-logo-container{
        width: 75px;
    }
    .preview-logo-title{
        font-size: 14px;
        bottom: 0;
    }
    .preview-doc-name {
        font-size: 18px;
    }
    .preview-header-textR,
    .preview-doc-mobile {
        font-size: 11px;
    }
    .preview-doc-professional-bio,
    .preview-doc-speciality {
        font-size: 12px;
    }
    .preview-patient-part {
        font-size: 12px;
    }
    .preview-patient-wrapper{
        margin-top: 10px;
    }
    .preview-signature-footer{
        font-size: 13px;
    }
    .preview-signature-image-wrapper {
        height: 42px;
        width: 126px;
    }
} */

@media print {
  .preview-prescription-page {
    padding: 0 0;
  }
}
