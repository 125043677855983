.prescription-page {
  background-color: #fff0;
  min-height: 100vh;
  /* padding: 50px 100px; */
  font-family: 'Nunito', sans-serif;

  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  /* overflow: auto; */
}

.prescription-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  /* padding: 20px 40px; */
  /* padding: 0px 0px 20px 0; */
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #7c839990;

  /* min-width: 1600px;
  max-width: 1600px; */
  width: 100%;
  font-family: 'Nunito', sans-serif;
}

.header-wrapper {
  display: flex;
  min-height: 160px;
  background-color: #f8f8f8;
  padding: 20px 40px 20px 40px;
}

/* .header-partL {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */

.logo-container {
  width: 130px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  /* margin: 0 15px; */
}
.logo-wrapper {
  object-fit: contain;
}
.logo-wrapper img {
  width: 95%;
}
.logo-title {
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  bottom: -5px;
}

.header-partR {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-textR {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: end;
  font-weight: 600;
  font-size: 15px;
  color: #7c8399;
}

.doc-name {
  font-size: 32px;
  font-weight: 700;
  color: #15978ae0;
}

.doc-speciality {
  font-size: 20px;
  font-weight: 700;
  margin-top: -5px;
}
.doc-professional-bio {
  font-size: 17px;
  font-weight: 600;
}

.doc-contact-section {
  display: flex;
  justify-content: flex-end;
}

.doc-mobile {
  display: block;
  font-size: 14.5px;
  font-style: italic;
}
.doc-email {
  margin-left: 5px;
  font-style: italic;
}
.doc-address {
  font-style: italic;
}

.patient-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2px;
  /* margin-top: 15px; */
  padding: 2px 37px 2px 37px;
  border-width: 0.6mm;
  border-left-width: 0;
  border-right-width: 0;
  border-color: #7c8399;
  border-style: solid;
}

.patient-details {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.patient-part {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: row;
  padding: 5px;
  font-size: 17px;
  color: #7c8399e0;
  font-weight: 600;
}

.patient-part span {
  color: #7c8399;
  font-weight: 700;
  margin-left: 10px;
}

/* .patient-details .patient-part:first-child{
    flex-grow: 2;
} */

.prescription-details-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  /* margin-bottom: 20px; */
  padding: 0px 40px 0px 40px;
}

.details-leftside {
  /* flex-grow: 2; */
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  min-width: 388px;
  max-width: 388px;
}
.details-divider {
  width: 2px;
  background-color: #7c8399;
  margin: 0 20px 0 10px;
}
.details-rightside {
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.prescription-item {
  flex-grow: 1;
  margin: 2px 0;
}
.prescription-item-title {
  padding: 2px 2px;
  font-size: 18px;
  font-weight: 700;
}
.prescription-item-content {
  padding-left: 30px;
  font-weight: 600;
  font-size: 14px;
}

.details-leftside .prescription-item {
  /* max-height: 140px; */
}
.details-leftside .prescription-item:nth-child(4) {
  flex-grow: 4;
  max-height: max-content;
}
.details-leftside .prescription-item:last-child {
  flex-grow: 1.5;
}

.investigation-wrapper {
  margin: 0 10px 0 0;
  min-width: 40%;
}

.details-rightside .prescription-item:first-child {
  flex-grow: 5;
  margin-bottom: 20px;
}

.details-rightside .prescription-item {
  margin-bottom: 35px;
  min-height: 70px;
}

.details-rightside .prescription-item .prescription-item-content {
  margin-left: 30px;
}

/* Medication */

.medication-wrapper {
  margin: 0 20px 10px 0;
  padding: 5px 0;
  font-size: 13px;
  border-width: 0;
  /* border-bottom-width: 0.5px; */
  border-style: solid;
  border-color: #7c839920;
}
.medication-brief {
  font-weight: 700;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  text-align: center;
}
.medication-brief .medicine-type {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #7c8399e0;
}
.medication-brief .medicine-strength {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #7c8399e0;
}
.medication-schedule {
  font-weight: 600;
  font-size: 12px;
}
.medication-does-meal-days {
  display: flex;
  flex-direction: row;
  font-weight: 600;
}
.medication-doseDrop,
.medication-mealtime {
  margin-right: 5px;
}
.medication-advice {
  font-weight: 800;
}
.medication-advice span {
  font-weight: 600;
}

#poweredby-sec {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-bottom: 0;

  justify-content: center;
  flex-wrap: wrap;
  padding-right: 50px;
  align-items: flex-end;
  margin-top: -5px;
  max-height: 95px;
}

.signature-container {
  display: flex;
  justify-content: flex-end;
  /* margin-top: -10px; */
  margin-bottom: 45px;
}
.signature-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-right: 50px; */
}
.signature-image-wrapper {
  height: 60px;
  width: 180px;
  margin: 5px 0 0 0;
  padding: 5px 10px;
  border-width: 0mm;
  border-style: solid;
  border-color: #7c839950;
  border-radius: 10px;
  overflow: hidden;
}
.signature-image {
  width: 100%;
  height: 100%;
}
.signature-dash {
  margin-top: -6px;
}
.signature-footer {
  margin-top: -8px;
  font-size: 16px;
  font-weight: 600;
}

.button-container {
  /* margin-top: 20px; */
  display: flex;
  background-color: #fff;
  border-width: 0px;
  border-style: solid;
  border-color: #7c8399;
  /* flex-wrap: wrap; */
  min-width: 1600px;
  max-width: 1600px;
}

.share-container,
.download-container,
.copy-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  /* padding: 10px 0; */
  cursor: pointer;
  background-color: #54b9a8;
}
.share-container,
.copy-container {
  border-width: 0 2px 0 0;
  border-style: solid;
  /* border-color: #7c8399; */

  border-color: #fff;
}

.copy-container {
  /* flex-grow: 2;
    padding-right: 4px; */

  min-width: 440px;
  max-width: 440px;
}

.share-container,
.download-container {
  flex-grow: 1;
}

.download-container {
  padding-right: 10px;
}

.share-wrapper,
.download-wrapper,
.copy-wrapper {
  /* background-color: #54b9a8; */
  color: #fff;
  padding: 10px 20px;
  min-width: 150px;
  text-align: center;
  border-radius: 5px;
  font-size: 23px;
  font-weight: 600;
}

.header-partM {
  min-width: 0;
}

#powerdby-logo-wrapper-left {
  display: 'flex';
  align-items: 'center';
  flex-grow: 1;
  margin-top: '10px';
}
#signature-sec {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-bottom: 0;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 50px;
  align-items: flex-end;
  /* margin-top: -5px; */
  max-height: 95px;
  margin-bottom: 30px;
}

/* #powerdby-logo-wrapper-right {
  display: none !important;
} */

/* @media (max-width: 880px) {
  .prescription-page {
    padding: 10px 15px;
    display: block;
  }
} */

/* @media (max-width: 880px) {
    .prescription-page {
        padding: 10px 15px;
    }
    #poweredby-sec{
        padding-right: 0;
    }
    
    
}

@media only screen and (max-width: 900px) {
    .header-wrapper{
        min-height: 100px;
    }
    .header-partL {
        flex-grow: 1;
    }
    .logo-container{
        width: 100px;
    }
    .logo-title{
        font-size: 15px;
        bottom: 0;
    }
    
    .patient-part {
        width: 49%;
    }
    .doc-name {
        font-size: 18px;
    }
    .header-textR,
    .doc-mobile {
        font-size: 12px;
    }
    .doc-professional-bio,
    .doc-speciality {
        font-size: 13px;
    }
    .patient-part {
        font-size: 13px;
    }
    .prescription-details-container {
        flex-direction: column;
    }
    .details-divider {
        width: 100%;
        height: 1px;
        background-color: #7c839950;
    }
    .prescription-item {
        margin: 15px 0;
    }
    .prescription-item-title {
        padding: 0 10px;
        font-size: 16px;
    }
    .prescription-item-content {
        padding-left: 40px;
        font-size: 12px;
    }
    
    .details-rightside .prescription-item:first-child{
        margin-top: 25px;
    }
    .details-rightside .prescription-item .prescription-item-content{
        margin-left: 20px;
    }
    .medication-wrapper {
        font-size: 11.5px;
    }
    .medication-wrapper .medication-brief {
        font-size: 13.5px;
    }

    .signature-wrapper {
        margin: 20px 0 5px 0;
    }
    .signature-image-wrapper {
        height: 50px;
        width: 150px;
    }
    
    .copy-container{
        flex-grow: 1;
        min-width: auto;
        max-width: none;
    }
    .copy-container, .share-container, .download-container {
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: #fff;
    }
}

@media (max-width: 500px) {
    .logo-container{
        width: 85px;
    }
    .doc-mobile{
        display: none;
    }
    .details-rightside .prescription-item .prescription-item-content{
        margin-left: 10px;
    }
    .signature-footer{
        font-size: 15px;
    }
}

@media (max-width: 380px) {
    .details-rightside .prescription-item .prescription-item-content{
        margin-left: 0;
    }
    .logo-container{
        width: 75px;
    }
    .logo-title{
        font-size: 14px;
        bottom: 0;
    }
    .doc-name {
        font-size: 18px;
    }
    .header-textR,
    .doc-mobile {
        font-size: 11px;
    }
    .doc-professional-bio,
    .doc-speciality {
        font-size: 12px;
    }
    .patient-part {
        font-size: 12px;
    }
    .patient-wrapper{
        margin-top: 10px;
    }
    .signature-footer{
        font-size: 13px;
    }
    .signature-image-wrapper {
        height: 42px;
        width: 126px;
    }
} */

/* Media quires add */
@media only screen and (max-width: 1200px) {
  .prescription-page {
    display: block;
  }
  .prescription-container {
    /* display: block; */
    min-width: 0px;
  }

  .button-container {
    min-width: 0px;
    flex-wrap: wrap;
  }
  #poweredby-sec {
    padding-right: 0;
  }
  .signature-image-wrapper {
    height: 50;
    width: 120;
  }
}
@media only screen and (max-width: 880px) {
  .details-leftside {
    min-width: 250px;
  }
}

@media only screen and (max-width: 768px) {
  .prescription-page {
    display: block;
  }
  .prescription-container {
    display: block;
    min-width: 0px;
  }

  .details-leftside {
    min-width: 0px;
  }
  #poweredby-sec {
    padding-right: 0;
  }

  .button-container {
    min-width: 0px;
    flex-wrap: wrap;
  }
  .copy-container {
    min-width: 0;
  }
  .prescription-item-title {
    font-size: 16px;
  }
  /* .logo-wrapper img {
    width: 85%;
  } */
  .header-textR {
    font-size: 14px;
  }
  .doc-name {
    font-size: 28px;
  }
  .share-wrapper,
  .download-wrapper,
  .copy-wrapper {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .logo-container {
    width: 120px;
  }
  .doc-name {
    display: flex;
  }
  .doc-speciality {
    display: flex;
  }
  .doc-contact-section {
    justify-content: flex-start;
  }
  .doc-professional-bio {
    display: flex;
  }
  .doc-address {
    display: flex;
  }
  .doc-professional-bio {
    font-size: 15px;
  }
  .header-wrapper {
    flex-wrap: wrap-reverse;
  }
  .share-wrapper,
  .download-wrapper,
  .copy-wrapper {
    font-size: 16px;
  }

  /* .logo-wrapper img {
    width: 75%;
  } */
  .header-partL {
    margin-top: 10px;
  }

  .header-partM {
    min-width: 10px;
  }
  .patient-details {
    display: block;
  }
  .prescription-details-container {
    display: block;
    padding-bottom: 95px;
  }
  #powerdby-logo-wrapper-right {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex !important;
  }
  #powerdby-logo-wrapper-left {
    display: none !important;
  }
  #poweredby-sec {
    display: block;
    padding-right: 0;
  }
  #signature-sec {
    padding-right: 0px;
    margin: 8px 0 10px 0;
  }
  .signature-image-wrapper {
    height: 50px;
    width: 120px;
  }
  .details-rightside .prescription-item .prescription-item-content {
    margin-left: 0;
  }
  .doc-name {
    font-size: 28px;
    font-weight: 700;
  }
  .doc-speciality {
    font-size: 18px;
  }
  .doc-mobile {
    font-size: 12px;
  }
  .header-textR {
    font-size: 14px;
  }
  .patient-part {
    font-size: 15px;
  }
  .prescription-item-title {
    font-size: 15px;
  }
  .details-rightside .prescription-item {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .download-container {
    margin-top: 2px;
  }
  .medication-brief {
    text-align: left;
  }
}
@media only screen and (max-width: 375px) {
  .prescription-item-title {
    font-size: 14px;
  }
  /* .logo-wrapper img {
    width: 65%;
  } */
}

@media print {
  .prescription-page {
    padding: 0 0;
  }
}



@media only screen and (max-width: 600px) {

  .brand-logo{
    width: 60px !important;
    text-align: left;
  }
  .logo-container{
     width: 130px;
    padding: 5px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: flex-start;
    -webkit-justify-content: center;
    justify-content: flex-start;
    position: relative;   
  }
  .header-partL{
    width: 20%;
    /* border: 1px solid red; 
    background: url(https://abacusitbd.com/wp-content/uploads/2023/05/sneho.png);
    */
    background-repeat: no-repeat;
    z-index: 1000000000;
    /* background-color: #c70b0b; */
    background-size: 100% 100%;
  }
  .copy-container, .share-container{
      width: 30%;
  }
  .download-container{
    width: 100%;
  }
  .header-partR, .header-textR{
    padding-left: 20px;
    width: 80%
  }
  .header-partL {
    width: 20%;
    height: 65px;  
  }
  .header-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .doc-name{
    text-align: left;
  }

}

@media print {
  .logo-container,
  .logo-wrapper {
    display: block !important;
  }
}
