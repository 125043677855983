.modal-container {
    display: flex;
    justify-content: center;
    position: fixed;
    background-color: #63646540;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    left: 0;
    z-index: 1;
    font-family: "Nunito", sans-serif;
}
.modal-container-view {
    visibility: visible;
    /* transform: scaleY(0.00) scaleX(0); */
    /* animation: unfoldIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; */
    animation: unfoldIn 0.4s ease-in-out forwards;
}

.modal-container-out {
    transform: scale(1);
    /* animation: unfoldOut 1s 0s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; */
    animation: unfoldOut 0.4s 0s ease-in-out forwards;
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    height: 250px;
    width: 500px;
    background-color: #fff;
    border-radius: 5px;
    /* transition: transform 2s; */
}

.modal-wrapper-view{
    transform: translateY(30%);
    animation: zoomIn 0.4s 0s ease-in-out forwards;
}

.modal-wrapper-out{
    /* transform: scale(1) translateY(30%); */
    animation: zoomOut 0.4s ease-in-out forwards;
}


.modal-title-wrapper{
    height: 25%;
    display: flex;
    padding: 0 10px;
    align-items: center;
    border: #7C839920 1px solid;
}
.content-wrapper{
    flex-grow: 1;
    /* display: flex; */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 50%;
    padding: 10px 20px;
    border: #7C839920 1px solid;
}
.button-wrapper{
    height: 25%;
    display: flex;
    padding: 0 10px;
    justify-content: flex-end;
    align-items: center;
    border: #7C839920 1px solid;
}

.modal-title{
    font-weight: 700;
    font-size: 18px;
    padding: 5px 10px;
    color: #7C8399;
    /* background-color: #cdca; */
}

.close-btn, .done-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    margin: 5px 10px 5px 5px;
    height: 35px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.close-btn{
    background-color: #7C8399;
}
.done-btn{
    background-color: #54b9a8;
}

.share-btn-wrapper{
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 3px 3px 8px;
    margin: 3px;
    border-radius: 3px;
    border: #54b9a8 1px solid;
}
.share-btn-txt{
    font-size: 13px;
    font-weight: 700;
    margin: 0 6px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}




@keyframes unfoldIn {
    0% {
        background-color: #63646500;
        /* opacity: 0.0; */
        /* transform: scaleY(0.001) scaleX(0); */
    }
    50% {
        background-color: #63646520;
        /* opacity: 0.5; */
        /* transform: scaleY(0.002) scaleX(1); */
    }
    100% {
        background-color: #63646540;
        /* opacity: 1.0; */
        /* transform: scaleY(1) scaleX(1); */
        visibility: visible;
    }
}

@keyframes unfoldOut {
    0% {
        background-color: #63646540;
        /* opacity: 1.0; */
        /* transform: scaleY(1) scaleX(1); */
    }
    50% {
        background-color: #63646520;
        /* opacity: 0.5; */
        /* transform: scaleY(0.002) scaleX(1); */
    }
    100% {
        background-color: #63646500;
        /* opacity: 0.0; */
        /* transform: scaleY(0.001) scaleX(0); */
        visibility: hidden;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0) translateY(30%);
    }
    55% {
        transform: scale(0) translateY(30%);
    }
    100% {
        transform: scale(1) translateY(30%);
    }
}

@keyframes zoomOut {
    0% {
        transform: scale(1) translateY(30%);
    }
    25% {
        transform: scale(0) translateY(30%);
    }
    100% {
        transform: scale(0) translateY(30%);
    }
}

.hidden{
    visibility: hidden;
}
